
import {
    defineComponent, ref,
    Ref, watch
} from 'vue';
import { account, distributor } from '@/data';

export default defineComponent({
    setup(props, { emit }) {
        const installerId = ref('');
        const communityId = ref('');
        const officeUUID = ref('');
        const type = ref(distributor.Community);
        const installerOptions = ref <{
            ID: string;
            Name: string;
            Community: object[];
            Special: '0'|'1';
            UUID: string;
            Office: {
                [key in string]: string;
            }[];
        }[]>([]);
        const communityOptions: Ref<{
            [key in string]: Array< object >;
        }> = ref({});
        const officeOptions: Ref<{
            [key in string]: Array< {
                [key in string]: string;
            } >;
        }> = ref({});
        // 获取该dis下所有installer账户
        account.getAllInstallerCommunity((res: Array<{
            ID: string;
            Name: string;
            Community: Array< object >;
            Special: '0'|'1';
            UUID: string;
            Office: Array< {
                [key in string]: string;
            } >;
        } >) => {
            installerOptions.value = res;
            communityOptions.value = {};
            officeOptions.value = {};
            res.forEach((item) => {
                communityOptions.value[item.ID] = item.Community;
                officeOptions.value[item.ID] = item.Office;
            });
        });

        // 监听是否选择安装商、社区相关信息
        const disableBtn = ref(true);
        watch([installerId, communityId, type, officeUUID], () => {
            disableBtn.value = (type.value === distributor.Community && (!(installerId.value === '' || communityId.value === '')))
                || (type.value === distributor.Villa && installerId.value === '') || (type.value === distributor.Office
                && (installerId.value === '' || officeUUID.value === ''));
        });

        const next = () => {
            let userType = 'distributorIns';
            let id = '';
            installerOptions.value.forEach((installer) => {
                if (installer.ID === installerId.value) {
                    id = installer.UUID;
                }
            });

            if (type.value === distributor.Community) {
                userType = 'distributorCom';
            } else if (type.value === distributor.Office) {
                let officeId = '';
                officeOptions.value[installerId.value].forEach((item) => {
                    if (item.UUID === officeUUID.value) {
                        officeId = item.ID;
                    }
                });
                id = `${officeUUID.value};${officeId}`;
                userType = 'distributorOffice';
            }
            emit('next', {
                type: userType,
                id
            });
        };

        // v6.4, modify by zyc, 新增dis-ins返回选项，选择后仅有单住户单选框, 2022.4.15
        const showType = ref(true);
        const changeInstaller = (val: string) => {
            showType.value = true;
            let isShowTypeFlag = true;
            installerOptions.value.forEach(
                (installer) => {
                    if (installer.ID === val && installer.Special === '1') {
                        isShowTypeFlag = false;
                    }
                }
            );
            if (!isShowTypeFlag) {
                type.value = '2';
            } else {
                type.value = '0';
            }
            showType.value = isShowTypeFlag;
        };

        return {
            type,
            installerId,
            communityId,
            officeUUID,
            installerOptions,
            communityOptions,
            officeOptions,
            distributor,
            disableBtn,
            changeInstaller,
            showType,
            next
        };
    }
});
