import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shell padding30px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choose_installer = _resolveComponent("choose-installer")
  const _component_base_subscription = _resolveComponent("base-subscription")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.step===0)
      ? (_openBlock(), _createBlock(_component_choose_installer, {
          key: 0,
          onNext: _ctx.setType
        }, null, 8, ["onNext"]))
      : (_openBlock(), _createBlock(_component_base_subscription, {
          key: 1,
          userType: _ctx.userType,
          "project-id": _ctx.installerUUID,
          "is-batch": _ctx.isBatch
        }, null, 8, ["userType", "project-id", "is-batch"]))
  ]))
}