
import {
    defineComponent, ref,
    Ref
} from 'vue';
import chooseInstaller from '@/components/view/distributor/choose-installer';
import baseSubscription from '@/components/view/common/subscription';
import { UserType } from '@/components/view/common/subscription/all.type';

interface ChooseType {
    type: UserType;
    id: string;
}
export default defineComponent({
    components: {
        chooseInstaller,
        baseSubscription
    },
    setup() {
        const step = ref(0);
        const installerUUID = ref('');
        const userType: Ref<UserType> = ref('distributorCom');
        const isBatch = ref(false);
        const setType = (chooseResult: ChooseType) => {
            userType.value = chooseResult.type;
            installerUUID.value = chooseResult.id;
            isBatch.value = userType.value === 'distributorCom';
            step.value = 1;
        };
        return {
            step,
            setType,
            userType,
            installerUUID,
            isBatch
        };
    }
});
