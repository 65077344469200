import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "info-container" }
const _hoisted_2 = { class: "info-item" }
const _hoisted_3 = { class: "info-item-bottom" }
const _hoisted_4 = { class: "info-item" }
const _hoisted_5 = { class: "info-item-bottom" }
const _hoisted_6 = {
  key: 0,
  class: "info-item"
}
const _hoisted_7 = { class: "info-item-bottom" }
const _hoisted_8 = { class: "info-item margin-top30px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_cus_button = _resolveComponent("cus-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$wordList.ProperAllTextInstaller), 1),
      _createVNode(_component_cus_select, {
        modelValue: _ctx.installerId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.installerId = $event)),
        filter: "",
        onChange: _ctx.changeInstaller
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.installerOptions, (item) => {
            return (_openBlock(), _createBlock(_component_cus_option, {
              key: item.ID,
              label: item.Name,
              value: item.ID
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("p", _hoisted_5, _toDisplayString(_ctx.$wordList.ProperAllTextProjectType), 1),
      (_ctx.showType)
        ? (_openBlock(), _createBlock(_component_el_radio, {
            key: 0,
            modelValue: _ctx.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.type = $event)),
            label: "0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllCommunity), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.showType)
        ? (_openBlock(), _createBlock(_component_el_radio, {
            key: 1,
            modelValue: _ctx.type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.type = $event)),
            label: "1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllOffice), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_radio, {
        modelValue: _ctx.type,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.type = $event)),
        label: "2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextVilla), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_ctx.installerId && _ctx.type == _ctx.distributor.Office)
      ? (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode("p", _hoisted_7, _toDisplayString(_ctx.$wordList.ProperAllOffice), 1),
          _createVNode(_component_cus_select, {
            modelValue: _ctx.officeUUID,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.officeUUID = $event)),
            filter: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.officeOptions[_ctx.installerId], (item) => {
                return (_openBlock(), _createBlock(_component_cus_option, {
                  key: item.ID,
                  label: item.Name,
                  value: item.UUID
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_8, [
      _createVNode(_component_cus_button, {
        onClick: _ctx.next,
        type: "blue",
        disabled: _ctx.disableBtn
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootNext), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}